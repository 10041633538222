<template>
  <div v-if="option">
    <p class="subtitle">{{ option.Name }}</p>
  </div>
</template>

<script>
import optionProvider from '@/providers/option'

export default {
  components: {},

  props: {
    optionId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      locationId: Number(this.$route.params.locationId),
      option: null,
    }
  },

  created() {
    this.getOptionData()
  },

  methods: {
    getOptionData() {
      let self = this

      optionProvider.methods
        .getOptionById(self.optionId, self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.option = response.data
          }
        })
    },
  },
}
</script>

<style></style>
